// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ThreeDots } from 'react-loader-spinner';
// import { toast } from 'react-toastify';

// const AddProjectBoqModal = ({ onClose, onUpdate }) => {
//   const [formData, setFormData] = useState({
//     project: '',
//     description: '',
//     pac: '',
//     boqType: 'Percent Rate',
//     percentValue: 0,
//     newPac: '',
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [projects, setProjects] = useState([]);
//   const [errors, setErrors] = useState({});

//   const { project, description, pac, boqType, percentValue, newPac } = formData;

//   useEffect(() => {
//     const fetchProjects = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
//         setProjects(response.data);
//       } catch (error) {
//         console.error('Error fetching projects:', error.message);
//         toast.error('Failed to load projects.');
//       } finally {
//         setIsLoading(false);
//       }
//     };
//     fetchProjects();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//       newPac: name === 'percentValue' ? calculateNewPac(value) : formData.newPac,
//     });
//     setErrors({ ...errors, [name]: '' });
//   };

//   const handlePercentChange = (changeValue) => {
//     const updatedPercentValue = parseFloat(percentValue) + changeValue;
//     setFormData({
//       ...formData,
//       percentValue: updatedPercentValue,
//       newPac: calculateNewPac(updatedPercentValue),
//     });
//   };

//   const calculateNewPac = (percent) => {
//     const pacValue = parseFloat(pac) || 0;
//     const percentFloat = parseFloat(percent) || 0;
//     return (pacValue + (pacValue * percentFloat) / 100).toFixed(6);
//   };

//   const validate = () => {
//     const formErrors = {};
//     if (!project) formErrors.project = 'Project is required';
//     if (!description) formErrors.description = 'Description is required';
//     if (!pac) formErrors.pac = 'PAC value is required';
//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validate()) return;

//     setIsLoading(true);

//     try {
//       await axios.post(`${process.env.REACT_APP_LOCAL_URL}/projects/boq`, {
//         project,
//         description,
//         pac,
//         boqType,
//         percentValue,
//         newPac,
//       });
//       onUpdate();
//       toast.success('Project BOQ added successfully');
//       onClose();
//     } catch (error) {
//       console.error('Error adding project BOQ:', error);
//       setErrors({ apiError: 'Failed to add project BOQ' });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div id="addProjectBoqModal" className="modal fade show" role="dialog" style={{ display: 'block' }}>
//       <div className="modal-dialog modal-lg overflow-hidden" style={{ borderRadius: '20px' }}>
//         <div className="modal-content">
//           <form onSubmit={handleSubmit} autoComplete="off" noValidate>
//             <div className="modal-header" style={{ backgroundColor: '#00509d', color: 'white' }}>
//               <h5 className="modal-title">Add Project BOQ</h5>
//               <button type="button" className="button_details" onClick={onClose}>
//                 <i className="fa-solid fa-xmark"></i>
//               </button>
//             </div>
//             <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//               {errors.apiError && <div className="alert alert-danger">{errors.apiError}</div>}
//               <div className="form-group">
//                 <label>Project Name<span style={{ color: 'red' }}>*</span></label>
//                 <select
//                   name="project"
//                   value={project}
//                   onChange={(e) => handleChange(e)}
//                   className={`form-control ${errors.project ? 'is-invalid' : ''}`}
//                 >
//                   <option value="">Select Project</option>
//                   {projects.map((proj) => (
//                     <option key={proj.id} value={proj.id}>
//                       {proj.projectName}
//                     </option>
//                   ))}
//                 </select>
//                 {errors.project && <div className="invalid-feedback">{errors.project}</div>}
//               </div>

//               <div className="form-group">
//                 <label>Project Description<span style={{ color: 'red' }}>*</span></label>
//                 <textarea
//                   name="description"
//                   value={description}
//                   onChange={handleChange}
//                   className={`form-control ${errors.description ? 'is-invalid' : ''}`}
//                   placeholder="Project Description"
//                 ></textarea>
//                 {errors.description && <div className="invalid-feedback">{errors.description}</div>}
//               </div>
//               <div className="row">
//                 <div className="form-group col-md-6">
//                   <label>Project PAC<span style={{ color: 'red' }}>*</span></label>
//                   <input
//                     name="pac"
//                     value={pac}
//                     onChange={handleChange}
//                     type="number"
//                     className={`form-control ${errors.pac ? 'is-invalid' : ''}`}
//                     placeholder="PAC Value"
//                   />
//                   {errors.pac && <div className="invalid-feedback">{errors.pac}</div>}
//                 </div>

//                 <div className="form-group col-md-6">
//                   <label>Project BOQ Type</label>
//                   <select
//                     name="boqType"
//                     value={boqType}
//                     onChange={handleChange}
//                     className="form-control"
//                   >
//                     <option value="Percent Rate">Percent Rate</option>
//                     <option value="Item Rate">Item Rate</option>
//                   </select>
//                 </div>
//               </div>
//               {boqType === 'Percent Rate' && (
//                 <div className="row">
//                   <label>Percentage Value</label>
//                   <div className="form-group col-md-6">
//                     <div className="d-flex align-items-center justify-content-between">
//                       <div className='form-group col-md-4 m-0'>
//                         <button
//                           type="button"
//                           className="btn btn-primary mr-2"
//                           onClick={() => handlePercentChange(-1)}
//                           disabled={percentValue <= 0}> - </button>

//                         <button type="button" className="btn btn-primary ml-2" onClick={() => handlePercentChange(1)}>
//                           +
//                         </button>
//                       </div>
//                       <div className='form-group col-md-8 m-0'>
//                         <input
//                           name="percentValue"
//                           value={percentValue}
//                           onChange={handleChange}
//                           className="form-control "
//                           placeholder="Enter percentage"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group col-md-6 ">
//                     <input
//                       name="newPac"
//                       value={newPac}
//                       readOnly
//                       className="form-control"
//                       placeholder="New PAC Value"
//                     />
//                   </div>
//                 </div>
//               )}
//               {boqType === 'Percent Rate' && (
//                 <div className="form-group">
//                   <label>New PAC Value</label>
//                   <input
//                     name="newPac"
//                     value={newPac}
//                     readOnly
//                     className="form-control"
//                     placeholder="New PAC Value"
//                   />
//                 </div>
//               )}
//             </div>
//             <div className="modal-footer" style={{ backgroundColor: '#00509d', color: 'white' }}>
//               <button type="submit" className="button_details" disabled={isLoading}>
//                 {isLoading ? <ThreeDots color="#fff" height={10} width={40} /> : 'Submit'}
//               </button>
//               <button type="button" className="button_details" onClick={onClose}>Close</button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddProjectBoqModal;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const AddProjectBoqModal = ({ onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    project: '',
    description: '',
    pac: '',
    boqType: 'Percent Rate',
    percentValue: 0,
    newPac: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState({});
  const [isPlusActive, setIsPlusActive] = useState(true); // State to track active button

  const { project, description, pac, boqType, percentValue, newPac } = formData;

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error.message);
        toast.error('Failed to load projects.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchProjects();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      newPac: name === 'percentValue' ? calculateNewPac(value) : formData.newPac,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const calculateNewPac = (percent) => {
    const pacValue = parseFloat(pac) || 0;
    const percentFloat = parseFloat(percent) || 0;
    return (pacValue + (pacValue * percentFloat) / 100 * (isPlusActive ? 1 : -1)).toFixed(6);
  };

  const validate = () => {
    const formErrors = {};
    if (!project) formErrors.project = 'Project is required';
    if (!description) formErrors.description = 'Description is required';
    if (!pac) formErrors.pac = 'PAC value is required';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_LOCAL_URL}/projects/boq`, {
        project,
        description,
        pac,
        boqType,
        percentValue,
        newPac,
      });
      onUpdate();
      toast.success('Project BOQ added successfully');
      onClose();
    } catch (error) {
      console.error('Error adding project BOQ:', error);
      setErrors({ apiError: 'Failed to add project BOQ' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="addProjectBoqModal" className="modal fade show" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg overflow-hidden" style={{ borderRadius: '20px' }}>
        <div className="modal-content">
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <div className="modal-header" style={{ backgroundColor: '#00509d', color: 'white' }}>
              <h5 className="modal-title">Add Project BOQ</h5>
              <button type="button" className="button_details" onClick={onClose}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
              {errors.apiError && <div className="alert alert-danger">{errors.apiError}</div>}
              <div className="form-group">
                <label>Project Name<span style={{ color: 'red' }}>*</span></label>
                <select
                  name="project"
                  value={project}
                  onChange={(e) => handleChange(e)}
                  className={`form-control ${errors.project ? 'is-invalid' : ''}`}
                >
                  <option value="">Select Project</option>
                  {projects.map((proj) => (
                    <option key={proj.id} value={proj.id}>
                      {proj.projectName}
                    </option>
                  ))}
                </select>
                {errors.project && <div className="invalid-feedback">{errors.project}</div>}
              </div>

              <div className="form-group">
                <label>Project Description<span style={{ color: 'red' }}>*</span></label>
                <textarea
                  name="description"
                  value={description}
                  onChange={handleChange}
                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                  placeholder="Project Description"
                ></textarea>
                {errors.description && <div className="invalid-feedback">{errors.description}</div>}
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>Project PAC<span style={{ color: 'red' }}>*</span></label>
                  <input
                    name="pac"
                    value={pac}
                    onChange={handleChange}
                    type="number"
                    className={`form-control ${errors.pac ? 'is-invalid' : ''}`}
                    placeholder="PAC Value"
                  />
                  {errors.pac && <div className="invalid-feedback">{errors.pac}</div>}
                </div>

                <div className="form-group col-md-6">
                  <label>Project BOQ Type</label>
                  <select
                    name="boqType"
                    value={boqType}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="Percent Rate">Percent Rate</option>
                    <option value="Item Rate">Item Rate</option>
                  </select>
                </div>
              </div>
              {boqType === 'Percent Rate' && (
                <div className="row">
                  <label>Percentage Value</label>
                  <div className="form-group col-md-6">
                    <div className="  d-flex align-items-center justify-content-between">
                      <div className=' d-flex align-items-center justify-content-between form-group col-md-4 m-0'>
                        <button
                          type="button"
                          className={`btn ${isPlusActive ? 'btn-outline-danger' : 'btn-danger'}`}
                          onClick={() => setIsPlusActive(false)}
                          disabled={!isPlusActive}
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className={`btn ${isPlusActive ? 'btn-primary' : 'btn-outline-primary'}`}
                          onClick={() => setIsPlusActive(true)}
                          disabled={isPlusActive}
                        >
                          +
                        </button>
                      </div>
                      <div className='form-group col-md-8 m-0'>
                        <input
                          name="percentValue"
                          value={percentValue}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter percentage"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      name="newPac"
                      value={newPac}
                      readOnly
                      className="form-control"
                      placeholder="New PAC Value"
                    />
                  </div>
                </div>
              )}
              {boqType === 'Percent Rate' && (
                <div className="form-group">
                  <label>New PAC Value</label>
                  <input
                    name="newPac"
                    value={newPac}
                    readOnly
                    className="form-control"
                    placeholder="New PAC Value"
                  />
                </div>
              )}
            </div>
            <div className="modal-footer" style={{ backgroundColor: '#00509d', color: 'white' }}>
              <button type="submit" className="button_details" disabled={isLoading}>
                {isLoading ? <ThreeDots color="#fff" height={10} width={40} /> : 'Submit'}
              </button>
              <button type="button" className="button_details" onClick={onClose}>Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProjectBoqModal;