import React, { useEffect, useState } from 'react';
import myLogo from '../../images/CashBackground.jpg';
import axios from 'axios';

const LabourPaymentListReportPreview = ({ record, onClose }) => {
    const { 
        labour = [], 
        attendance = [], 
        paymentDetails = {}, 
        selectedYear, 
        selectedMonth ,
        selectedProjects
    } = record || {};
    
    const [setting, setSetting] = useState({});

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        };
        fetchSetting();
    }, []);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('default', { month: 'long' });
    };

    const calculateTotalAttendance = (labourId) => {
        const labourAttendance = attendance.filter((record) => record.labourId === labourId);
        const labourer = labour.find((labourer) => labourer.id === labourId);

        const rates = {
            dayShift: labourer?.dayShift || 0,
            nightShift: labourer?.nightShift || 0,
            halfDayShift: labourer?.halfDayShift || 0,
            absentShift: labourer?.absentShift || 0,
            overtimeHrs: labourer?.overtimeHrs || 0,
        };

        let totals = {
            dayShift: 0,
            nightShift: 0,
            halfDayShift: 0,
            absentShift: 0,
            overtime: 0,
            amount: 0
        };

        labourAttendance.forEach((record) => {
            totals.dayShift += record.dayShift || 0;
            totals.nightShift += record.nightShift || 0;
            totals.halfDayShift += record.halfDayShift || 0;
            totals.absentShift += record.absentShift || 0;
            totals.overtime += record.overtimeHours || 0;

            totals.amount += 
                (record.dayShift || 0) * rates.dayShift +
                (record.nightShift || 0) * rates.nightShift +
                (record.halfDayShift || 0) * rates.halfDayShift +
                (record.absentShift || 0) * rates.absentShift +
                (record.overtimeHours || 0) * rates.overtimeHrs;
        });

        return totals;
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="container-fluid">
            <div className="row p-1">
                <div className="modal-content">
                    <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
                        <div className="logo p-1" style={{ height: "50px", width: "100px" }}>
                            <img
                                src={setting.landingPageLogo
                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                                    : myLogo}
                                style={{ height: "100%", objectFit: "cover" }}
                                alt="Company Logo"
                            />
                        </div>
                        <div className='text-center'>
                            <h4 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder m-0'>
                                Labour Payment Report
                            </h4>
                            <small style={{ color: "#00509d" }}>
                                <small>
                                    Project: {
                                        record?.recordData?.find(project =>
                                            project.id === parseInt(record.selectedProjects)
                                        )?.projectShortName || 'No Project Selected'
                                    }
                                </small>
                            </small>
                        </div>
                        <div className='text-center'>
                            <h5 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder m-0'>
                                {setting.title || "PETTY CASH MANAGER"}
                            </h5>
                            <small style={{ color: "#00509d" }}>
                                {getMonthName(selectedMonth)} {selectedYear}
                            </small>
                        </div>
                    </div>

                    <div className="card-body">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ whiteSpace: "nowrap" }}>Labour Name</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Labour ID</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Day </th>
                                    <th style={{ whiteSpace: "nowrap" }}>Night </th>
                                    <th style={{ whiteSpace: "nowrap" }}>Half</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Absents</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Ovrt. (Hrs)</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Total Amt</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Paid Amt</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Due Amt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {labour.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" className="text-center">No payment records found</td>
                                    </tr>
                                ) : (
                                    labour.map((labourer) => {
                                        const totals = calculateTotalAttendance(labourer.id);
                                        const paid = paymentDetails[labourer.id] || 0;
                                        const due = totals.amount - paid;

                                        return (
                                            <tr key={labourer.id}>
                                                <td>{labourer.labourName}</td>
                                                <td>{labourer.labourId}</td>
                                                <td className='text-center'>{totals.dayShift} </td>
                                                <td className='text-center'>{totals.nightShift}</td>
                                                <td className='text-center'>{totals.halfDayShift}</td>
                                                <td className='text-center'>{totals.absentShift}</td>
                                                <td className='text-center'>{totals.overtime} Hr</td>
                                                <td className="text-end">₹{totals.amount.toFixed(2)}</td>
                                                <td className="text-end">₹{paid.toFixed(2)}</td>
                                                <td className="text-end">₹{due.toFixed(2)}</td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer p-1 d-flex justify-content-between">
                        <small>Powered By - Petty Cash Manager</small>
                        <div className="d-flex gap-2">
                            <button onClick={handlePrint} className="btn btn-success">
                                <i className="fa fa-download me-2"></i> Print
                            </button>
                            <button onClick={onClose} className="btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LabourPaymentListReportPreview;