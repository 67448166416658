






// import React, { useEffect, useState } from 'react';
// import myLogo from '../../images/CashBackground.jpg';
// import axios from 'axios';

// const AmtAccor_AttendReportPreview = ({ record, onClose }) => {
//     const {
//         labour = [],
//         attendance = [],
//         paymentDetails = {},
//         selectedYear,
//         selectedMonth
//     } = record || {};

//     const [setting, setSetting] = useState({});

//     useEffect(() => {
//         const fetchSetting = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//                 setSetting(response.data);
//             } catch (error) {
//                 console.error('Error fetching settings', error);
//             }
//         };
//         fetchSetting();
//     }, []);

//     const getMonthName = (monthNumber) => {
//         const date = new Date();
//         date.setMonth(monthNumber - 1);
//         return date.toLocaleString('default', { month: 'long' });
//     };

//     const calculateTotalAttendance = (labourId) => {
//         const labourAttendance = attendance.filter((record) => record.labourId === labourId);
//         console.log("Labour Attendance for ID", labourId, ":", labourAttendance); // Debugging: Log filtered attendance

//         // Find the labourer to get their specific rates
//         const labourer = labour.find((labourer) => labourer.id === labourId);
//         const labourerRates = {
//             dayShiftRate: labourer?.dayShift || 0,
//             nightShiftRate: labourer?.nightShift || 0,
//             halfDayShiftRate: labourer?.halfDayShift || 0,
//             absentShiftRate: labourer?.absentShift || 0,
//             overtimeRate: labourer?.overtimeHrs || 0,
//         };

//         console.log("Rates for Labour ID", labourId, ":", labourerRates); // Debugging: Log rates

//         let totalDayShift = 0;
//         let totalNightShift = 0;
//         let totalHalfDayShift = 0;
//         let totalAbsentShift = 0;
//         let totalOvertime = 0;
//         let totalDayAmount = 0;
//         let totalNightAmount = 0;
//         let totalHalfDayAmount = 0;
//         let totalAbsentAmount = 0;
//         let totalOvertimeAmount = 0;
//         let totalAmount = 0;

//         labourAttendance.forEach((record) => {
//             totalDayShift += record.dayShift || 0;
//             totalNightShift += record.nightShift || 0;
//             totalHalfDayShift += record.halfDayShift || 0;
//             totalAbsentShift += record.absentShift || 0;
//             totalOvertime += record.overtimeHours || 0;

//             const dayShiftAmount = (record.dayShift || 0) * (labourerRates.dayShiftRate || 0);
//             const nightShiftAmount = (record.nightShift || 0) * (labourerRates.nightShiftRate || 0);
//             const halfDayShiftAmount = (record.halfDayShift || 0) * (labourerRates.halfDayShiftRate || 0);
//             const absentShiftAmount = (record.absentShift || 0) * (labourerRates.absentShiftRate || 0);
//             const overtimeAmount = (record.overtimeHours || 0) * (labourerRates.overtimeRate || 0);

//             totalDayAmount += dayShiftAmount;
//             totalNightAmount += nightShiftAmount;
//             totalHalfDayAmount += halfDayShiftAmount;
//             totalAbsentAmount += absentShiftAmount;
//             totalOvertimeAmount += overtimeAmount;

//             totalAmount += dayShiftAmount + nightShiftAmount + halfDayShiftAmount + absentShiftAmount + overtimeAmount;

//             console.log("Record Details for Labour ID", labourId, ":", {
//                 dayShift: record.dayShift,
//                 nightShift: record.nightShift,
//                 halfDayShift: record.halfDayShift,
//                 absentShift: record.absentShift,
//                 overtimeHours: record.overtimeHours,
//                 dayShiftAmount,
//                 nightShiftAmount,
//                 halfDayShiftAmount,
//                 absentShiftAmount,
//                 overtimeAmount,
//                 totalAmount,
//             }); // Debugging: Log each record's details
//         });

//         console.log("Total Amount for Labour ID", labourId, ":", totalAmount); // Debugging: Log total amount
//         return {
//             totalDayShift,
//             totalNightShift,
//             totalHalfDayShift,
//             totalAbsentShift,
//             totalOvertime,
//             totalDayAmount,
//             totalNightAmount,
//             totalHalfDayAmount,
//             totalAbsentAmount,
//             totalOvertimeAmount,
//             totalAmount,
//         };
//     };


//     const handlePrint = () => {
//         window.print();
//     };

//     return (
//         <div className="container-fluid">
//             <div className="row p-1">
//                 <div className="modal-content">
//                     <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
//                         <div className="logo p-1" style={{ height: "50px", width: "100px" }}>
//                             <img
//                                 src={setting.landingPageLogo
//                                     ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
//                                     : myLogo}
//                                 style={{ height: "100%", objectFit: "cover" }}
//                                 alt="Company Logo"
//                             />
//                         </div>
//                         <h4 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder m-0'>
//                             Labour Payment Report
//                         </h4>
//                         <div className='text-center'>
//                             <h5 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder m-0'>
//                                 {setting.title || "PETTY CASH MANAGER"}
//                             </h5>
//                             <small style={{ color: "#00509d" }}>
//                                 {getMonthName(selectedMonth)} {selectedYear}
//                             </small>
//                         </div>
//                     </div>

//                     <div className="card-body">
//                         <table className="table table-striped table-bordered">
//                             <thead>
//                                 <tr>
//                                     <th style={{ whiteSpace: "nowrap" }}>Labour Name</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Labour ID</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Day</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Night</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Half</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Absent</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Overtime hrs</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Total Day Amt</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Total Night Amt</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Total HalfDay Amt</th>
//                                     <th style={{ whiteSpace: "nowrap" }}>Total Overtime Amt</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                                             {labour.length === 0 ? (
//                                                                 <tr>
//                                                                     <td colSpan="14" className="text-center">No Payment Found.</td>
//                                                                 </tr>
//                                                             ) : (
//                                                                 labour.map((labourer) => {
//                                                                     const {
//                                                                         totalDayShift,
//                                                                         totalNightShift,
//                                                                         totalHalfDayShift,
//                                                                         totalAbsentShift,
//                                                                         totalOvertime,
//                                                                         totalDayAmount,
//                                                                         totalNightAmount,
//                                                                         totalHalfDayAmount,
//                                                                         totalAbsentAmount,
//                                                                         totalOvertimeAmount,
//                                                                         totalAmount,
//                                                                     } = calculateTotalAttendance(labourer.id);
//                                                                     const totalPaid = paymentDetails[labourer.id] || 0;
//                                                                     const totalDue = totalAmount - totalPaid;

//                                                                     return (
//                                                                         <tr key={labourer.id}>
//                                                                             <td>{labourer.labourName}</td>
//                                                                             <td>{labourer.labourId}</td>
//                                                                             <td>{totalDayShift} </td>
//                                                                             <td>{totalNightShift}</td>
//                                                                             <td>{totalHalfDayShift} </td>
//                                                                             <td>{totalAbsentShift} </td>
//                                                                             <td>{totalOvertime} Hrs</td>
//                                                                             <td className="text-end">&#x20B9;{totalDayAmount ? totalDayAmount.toFixed(2) : "0.00"}</td>
//                                                                             <td className="text-end">&#x20B9;{totalNightAmount ? totalNightAmount.toFixed(2) : "0.00"}</td>
//                                                                             <td className="text-end">&#x20B9;{totalHalfDayAmount ? totalHalfDayAmount.toFixed(2) : "0.00"}</td>
//                                                                             <td className="text-end">&#x20B9;{totalOvertimeAmount ? totalOvertimeAmount.toFixed(2) : "0.00"}</td>
//                                                                         </tr>
//                                                                     );
//                                                                 })
//                                                             )}
//                                                         </tbody>
//                         </table>
//                     </div>

//                     <div className="modal-footer p-1 d-flex justify-content-between">
//                         <small>Powered By - Petty Cash Manager</small>
//                         <div className="d-flex gap-2">
//                             <button onClick={handlePrint} className="btn btn-success">
//                                 <i className="fa fa-download me-2"></i> Print
//                             </button>
//                             <button onClick={onClose} className="btn btn-danger">Close</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AmtAccor_AttendReportPreview;



import React, { useEffect, useState } from 'react';
import myLogo from '../../images/CashBackground.jpg';
import axios from 'axios';

const AmtAccor_AttendReportPreview = ({ record, onClose }) => {
    const {
        labour = [],
        attendance = [],
        paymentDetails = {},
        selectedYear,
        selectedMonth,
        // selectedProjects
    } = record || {};
    console.log(record)

    const [setting, setSetting] = useState({});

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        };
        fetchSetting();
    }, []);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('default', { month: 'long' });
    };

    const calculateTotalAttendance = (labourId) => {
        const labourAttendance = attendance.filter((record) => record.labourId === labourId);
        const labourer = labour.find((labourer) => labourer.id === labourId);

        const rates = {
            dayShift: labourer?.dayShift || 0,
            nightShift: labourer?.nightShift || 0,
            halfDayShift: labourer?.halfDayShift || 0,
            overtime: labourer?.overtimeHrs || 0,
        };

        return labourAttendance.reduce((acc, record) => ({
            totalDayShift: acc.totalDayShift + (record.dayShift || 0),
            totalNightShift: acc.totalNightShift + (record.nightShift || 0),
            totalHalfDayShift: acc.totalHalfDayShift + (record.halfDayShift || 0),
            totalAbsentShift: acc.totalAbsentShift + (record.absentShift || 0),
            totalOvertime: acc.totalOvertime + (record.overtimeHours || 0),
            totalDayAmount: acc.totalDayAmount + (record.dayShift || 0) * rates.dayShift,
            totalNightAmount: acc.totalNightAmount + (record.nightShift || 0) * rates.nightShift,
            totalHalfDayAmount: acc.totalHalfDayAmount + (record.halfDayShift || 0) * rates.halfDayShift,
            totalOvertimeAmount: acc.totalOvertimeAmount + (record.overtimeHours || 0) * rates.overtime,
        }), {
            totalDayShift: 0,
            totalNightShift: 0,
            totalHalfDayShift: 0,
            totalAbsentShift: 0,
            totalOvertime: 0,
            totalDayAmount: 0,
            totalNightAmount: 0,
            totalHalfDayAmount: 0,
            totalOvertimeAmount: 0,
        });
    };

    // Process labour data and calculate grand totals
    const processedLabour = labour.map(labourer => {
        const totals = calculateTotalAttendance(labourer.id);
        const totalPaid = paymentDetails[labourer.id] || 0;
        const totalDue = (totals.totalDayAmount + totals.totalNightAmount +
            totals.totalHalfDayAmount + totals.totalOvertimeAmount) - totalPaid;

        return { labourer, totals, totalPaid, totalDue };
    });

    const grandTotals = processedLabour.reduce((acc, curr) => ({
        totalDayShift: acc.totalDayShift + curr.totals.totalDayShift,
        totalNightShift: acc.totalNightShift + curr.totals.totalNightShift,
        totalHalfDayShift: acc.totalHalfDayShift + curr.totals.totalHalfDayShift,
        totalAbsentShift: acc.totalAbsentShift + curr.totals.totalAbsentShift,
        totalOvertime: acc.totalOvertime + curr.totals.totalOvertime,
        totalDayAmount: acc.totalDayAmount + curr.totals.totalDayAmount,
        totalNightAmount: acc.totalNightAmount + curr.totals.totalNightAmount,
        totalHalfDayAmount: acc.totalHalfDayAmount + curr.totals.totalHalfDayAmount,
        totalOvertimeAmount: acc.totalOvertimeAmount + curr.totals.totalOvertimeAmount,
        totalPaid: acc.totalPaid + curr.totalPaid,
        totalDue: acc.totalDue + curr.totalDue,
    }), {
        totalDayShift: 0,
        totalNightShift: 0,
        totalHalfDayShift: 0,
        totalAbsentShift: 0,
        totalOvertime: 0,
        totalDayAmount: 0,
        totalNightAmount: 0,
        totalHalfDayAmount: 0,
        totalOvertimeAmount: 0,
        totalPaid: 0,
        totalDue: 0,
    });

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="container-fluid">
            <div className="row p-1">
                <div className="modal-content">
                    {/* Header Section */}
                    <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
                        <div className="logo p-1" style={{ height: "50px", width: "100px" }}>
                            <img
                                src={setting.landingPageLogo
                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                                    : myLogo}
                                style={{ height: "100%", objectFit: "cover" }}
                                alt="Company Logo"
                            />
                        </div>
                        <div className='text-center'>
                            <h4 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder m-0'>
                                Labour Payment Report
                            </h4>
                            <small style={{ color: "#00509d" }}>
                                <small>
                                    Project: {
                                        record?.recordData?.find(project =>
                                            project.id === parseInt(record.selectedProjects)
                                        )?.projectShortName || 'No Project Selected'
                                    }
                                </small>
                            </small>
                        </div>
                        <div className='text-center'>
                            <h5 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder m-0'>
                                {setting.title || "PETTY CASH MANAGER"}
                            </h5>
                            <small style={{ color: "#00509d" }}>
                                {getMonthName(selectedMonth)} {selectedYear}
                            </small>
                        </div>
                    </div>

                    {/* Main Report Table */}
                    <div className="card-body">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ whiteSpace: "nowrap" }}>Labour Name</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Labour ID</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Day</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Night</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Half</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Absent</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Overtime</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Day Amt</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Night Amt</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Half Amt</th>
                                    <th style={{ whiteSpace: "nowrap" }}>OT Amt</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Paid</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processedLabour.length === 0 ? (
                                    <tr>
                                        <td colSpan="13" className="text-center">No Payment Records Found</td>
                                    </tr>
                                ) : (
                                    <>
                                        {processedLabour.map(({ labourer, totals, totalPaid, totalDue }) => (
                                            <tr key={labourer.id}>
                                                <td>{labourer.labourName}</td>
                                                <td>{labourer.labourId}</td>
                                                <td>{totals.totalDayShift}</td>
                                                <td>{totals.totalNightShift}</td>
                                                <td>{totals.totalHalfDayShift}</td>
                                                <td>{totals.totalAbsentShift}</td>
                                                <td>{totals.totalOvertime} Hrs</td>
                                                <td className="text-end">₹{totals.totalDayAmount.toFixed(2)}</td>
                                                <td className="text-end">₹{totals.totalNightAmount.toFixed(2)}</td>
                                                <td className="text-end">₹{totals.totalHalfDayAmount.toFixed(2)}</td>
                                                <td className="text-end">₹{totals.totalOvertimeAmount.toFixed(2)}</td>
                                                <td className="text-end">₹{totalPaid.toFixed(2)}</td>
                                                <td className="text-end">₹{totalDue.toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        {/* Grand Total Row */}
                                        <tr className="fw-bold">
                                            <td colSpan="2">Grand Totals</td>
                                            <td>{grandTotals.totalDayShift}</td>
                                            <td>{grandTotals.totalNightShift}</td>
                                            <td>{grandTotals.totalHalfDayShift}</td>
                                            <td>{grandTotals.totalAbsentShift}</td>
                                            <td>{grandTotals.totalOvertime} Hrs</td>
                                            <td className="text-end">₹{grandTotals.totalDayAmount.toFixed(2)}</td>
                                            <td className="text-end">₹{grandTotals.totalNightAmount.toFixed(2)}</td>
                                            <td className="text-end">₹{grandTotals.totalHalfDayAmount.toFixed(2)}</td>
                                            <td className="text-end">₹{grandTotals.totalOvertimeAmount.toFixed(2)}</td>
                                            <td className="text-end">₹{grandTotals.totalPaid.toFixed(2)}</td>
                                            <td className="text-end">₹{grandTotals.totalDue.toFixed(2)}</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Footer Section */}
                    <div className="modal-footer p-1 d-flex justify-content-between">
                        <small>Powered By - Petty Cash Manager</small>
                        <div className="d-flex gap-2">
                            <button onClick={handlePrint} className="btn btn-success no-print">
                                <i className="fa fa-download me-2"></i> Print
                            </button>
                            <button onClick={onClose} className="btn btn-danger no-print">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmtAccor_AttendReportPreview;