import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import ProjectReportPreview from "./ProjectReportPreview";
import AmtAccor_AttendReportPreview from "./AmtAccor_AttendReportPreview";

const AmtAccor_AttendReport = ({ handleLogout, username }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [labour, setLabour] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedProjects, setSelectedProjects] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState({}); // To store payment details by labourId

    // Fetch all projects on component mount
    useEffect(() => {
        fetchProjects();
    }, []);

    // Fetch labour and associated data when a project, month, or year changes
    useEffect(() => {
        if (selectedProjects) {
            fetchLabour(selectedProjects);
        }
    }, [selectedProjects, selectedMonth, selectedYear]);

    const fetchProjects = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
            setProjects(response.data);
        } catch (error) {
            console.error("Error fetching projects:", error);
            toast.error("Failed to fetch projects. Please try again.");
        }
    };

    const fetchLabour = async (projectId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/labours/${projectId}`);
            const labourData = response.data;
            setLabour(labourData);
            console.log("FetchLabour Details:- ", labourData);

            if (Array.isArray(labourData) && labourData.length > 0) {
                // Fetch attendance and payment details for all labour IDs
                const labourIds = labourData.map((labour) => labour.id);
                await fetchAttendance(labourIds);
                await fetchAllPaymentDetails(labourIds);
            }
        } catch (error) {
            console.error("Error fetching labour:", error);
            toast.error("Failed to fetch labour details. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAttendance = async (labourIds) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/labour-attendance`, {
                labourIds,
                month: selectedMonth,
                year: selectedYear,
            });
            setAttendance(response.data);
            console.log("Attendance Data:", response.data); // Debugging: Log attendance data
        } catch (error) {
            console.error("Error fetching attendance:", error);
            toast.error("Failed to fetch attendance details. Please try again.");
        }
    };

    const fetchPaymentDetails = async (labourId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/labourpaymentlist/labourpaidyear`, {
                params: {
                    labourId,
                    year: selectedYear,
                    month: selectedMonth,
                },
            });

            const paymentDetails = response.data;

            if (Array.isArray(paymentDetails)) {
                const totalPaid = paymentDetails.reduce((sum, payment) => {
                    return sum + (payment.amountPaid || 0);
                }, 0);

                return totalPaid;
            }
            return 0;
        } catch (error) {
            console.error("Error fetching payment details:", error);
            toast.error("Failed to fetch payment details. Please try again.");
            return 0;
        }
    };

    const fetchAllPaymentDetails = async (labourIds) => {
        try {
            const paymentPromises = labourIds.map(fetchPaymentDetails);
            const paymentResults = await Promise.all(paymentPromises);

            const paymentData = labourIds.reduce((acc, labourId, index) => {
                acc[labourId] = paymentResults[index];
                return acc;
            }, {});

            setPaymentDetails(paymentData);
            console.log("Payment Details:", paymentData); // Debugging: Log payment details
        } catch (error) {
            console.error("Error fetching all payment details:", error);
            toast.error("Failed to fetch payment details. Please try again.");
        }
    };

    const calculateTotalAttendance = (labourId) => {
        const labourAttendance = attendance.filter((record) => record.labourId === labourId);
        console.log("Labour Attendance for ID", labourId, ":", labourAttendance); // Debugging: Log filtered attendance

        // Find the labourer to get their specific rates
        const labourer = labour.find((labourer) => labourer.id === labourId);
        const labourerRates = {
            dayShiftRate: labourer?.dayShift || 0,
            nightShiftRate: labourer?.nightShift || 0,
            halfDayShiftRate: labourer?.halfDayShift || 0,
            absentShiftRate: labourer?.absentShift || 0,
            overtimeRate: labourer?.overtimeHrs || 0,
        };

        console.log("Rates for Labour ID", labourId, ":", labourerRates); // Debugging: Log rates

        let totalDayShift = 0;
        let totalNightShift = 0;
        let totalHalfDayShift = 0;
        let totalAbsentShift = 0;
        let totalOvertime = 0;
        let totalDayAmount = 0;
        let totalNightAmount = 0;
        let totalHalfDayAmount = 0;
        let totalAbsentAmount = 0;
        let totalOvertimeAmount = 0;
        let totalAmount = 0;

        labourAttendance.forEach((record) => {
            totalDayShift += record.dayShift || 0;
            totalNightShift += record.nightShift || 0;
            totalHalfDayShift += record.halfDayShift || 0;
            totalAbsentShift += record.absentShift || 0;
            totalOvertime += record.overtimeHours || 0;

            const dayShiftAmount = (record.dayShift || 0) * (labourerRates.dayShiftRate || 0);
            const nightShiftAmount = (record.nightShift || 0) * (labourerRates.nightShiftRate || 0);
            const halfDayShiftAmount = (record.halfDayShift || 0) * (labourerRates.halfDayShiftRate || 0);
            const absentShiftAmount = (record.absentShift || 0) * (labourerRates.absentShiftRate || 0);
            const overtimeAmount = (record.overtimeHours || 0) * (labourerRates.overtimeRate || 0);

            totalDayAmount += dayShiftAmount;
            totalNightAmount += nightShiftAmount;
            totalHalfDayAmount += halfDayShiftAmount;
            totalAbsentAmount += absentShiftAmount;
            totalOvertimeAmount += overtimeAmount;

            totalAmount += dayShiftAmount + nightShiftAmount + halfDayShiftAmount + absentShiftAmount + overtimeAmount;

            console.log("Record Details for Labour ID", labourId, ":", {
                dayShift: record.dayShift,
                nightShift: record.nightShift,
                halfDayShift: record.halfDayShift,
                absentShift: record.absentShift,
                overtimeHours: record.overtimeHours,
                dayShiftAmount,
                nightShiftAmount,
                halfDayShiftAmount,
                absentShiftAmount,
                overtimeAmount,
                totalAmount,
            }); // Debugging: Log each record's details
        });

        console.log("Total Amount for Labour ID", labourId, ":", totalAmount); // Debugging: Log total amount
        return {
            totalDayShift,
            totalNightShift,
            totalHalfDayShift,
            totalAbsentShift,
            totalOvertime,
            totalDayAmount,
            totalNightAmount,
            totalHalfDayAmount,
            totalAbsentAmount,
            totalOvertimeAmount,
            totalAmount,
        };
    };


    // Pdf  
    const [detailsVisible, setDetailsVisible] = useState(false); // State for details modal
    const [showSidebar, setShowSidebar] = useState(true); // Controls sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // Controls search bar visibility
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    const handleDetails = () => {
        setSelectedRecord({
            status: projects.length > 0 ? projects[0].status : '',
            recordData: projects,
            labour:labour,
            attendance:attendance,
            selectedMonth:selectedMonth,
            selectedYear:selectedYear,
            selectedProjects:selectedProjects,
            paymentDetails:paymentDetails
        });
        setDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true);
        setShowSearchBar(true);
        setDetailsVisible(false);
    };



    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {detailsVisible ? (
                        <AmtAccor_AttendReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #00509d" }} className='overflow-hidden'>
                                    <div style={{ backgroundColor: "#00509d" }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Att & Pytm Report</div>
                                                <div className=" d-flex gap-3">
                                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                                        <label className='nunito text-white p-0 m-0'>Select Project:</label>
                                                        <select  style={{width:"50%"}} className="button_details overflow-hidden" value={selectedProjects} onChange={(e) => setSelectedProjects(e.target.value)}>
                                                            <option value="">Select Project</option>
                                                            {projects.map((project) => (
                                                                <option key={project.id} value={project.id}>
                                                                    {project.projectName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <label className='nunito text-white p-0 m-0'>Filter:</label>
                                                        <select
                                                       
                                                            className="button_details  overflow-hidden"
                                                            value={selectedMonth}
                                                            onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                                        >
                                                            {Array.from({ length: 12 }, (_, i) => (
                                                                <option key={i + 1} value={i + 1}>
                                                                    {new Date(0, i).toLocaleString("default", { month: "long" })}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            className="button_details overflow-hidden"
                                                            value={selectedYear}
                                                            onChange={(e) => setSelectedYear(Number(e.target.value))}
                                                        >
                                                            {Array.from({ length: 10 }, (_, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <button onClick={handleDetails} className="button_details">
                                                        <i className="fa fa-download"></i> PDF
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "550px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00BFFF" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Labour Name</th>
                                                                <th>Labour ID</th>
                                                                <th>Day</th>
                                                                <th>Night</th>
                                                                <th>Half</th>
                                                                <th>Absent</th>
                                                                <th>Overtime hrs</th>
                                                                <th>Total Day Amt</th>
                                                                <th>Total Night Amt</th>
                                                                <th>Total HalfDay Amt</th>
                                                                <th>Total Overtime Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {labour.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="14" className="text-center">No Payment Found.</td>
                                                                </tr>
                                                            ) : (
                                                                labour.map((labourer) => {
                                                                    const {
                                                                        totalDayShift,
                                                                        totalNightShift,
                                                                        totalHalfDayShift,
                                                                        totalAbsentShift,
                                                                        totalOvertime,
                                                                        totalDayAmount,
                                                                        totalNightAmount,
                                                                        totalHalfDayAmount,
                                                                        totalAbsentAmount,
                                                                        totalOvertimeAmount,
                                                                        totalAmount,
                                                                    } = calculateTotalAttendance(labourer.id);
                                                                    const totalPaid = paymentDetails[labourer.id] || 0;
                                                                    const totalDue = totalAmount - totalPaid;

                                                                    return (
                                                                        <tr key={labourer.id}>
                                                                            <td>{labourer.labourName}</td>
                                                                            <td>{labourer.labourId}</td>
                                                                            <td>{totalDayShift} </td>
                                                                            <td>{totalNightShift}</td>
                                                                            <td>{totalHalfDayShift} </td>
                                                                            <td>{totalAbsentShift} </td>
                                                                            <td>{totalOvertime} Hrs</td>
                                                                            <td className="text-end">&#x20B9;{totalDayAmount ? totalDayAmount.toFixed(2) : "0.00"}</td>
                                                                            <td className="text-end">&#x20B9;{totalNightAmount ? totalNightAmount.toFixed(2) : "0.00"}</td>
                                                                            <td className="text-end">&#x20B9;{totalHalfDayAmount ? totalHalfDayAmount.toFixed(2) : "0.00"}</td>
                                                                            <td className="text-end">&#x20B9;{totalOvertimeAmount ? totalOvertimeAmount.toFixed(2) : "0.00"}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AmtAccor_AttendReport;










