// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { ThreeDots } from "react-loader-spinner";
// import Sidebar from "../../components/sidebar/Sidebar";
// import SearchBar from "../../components/sidebar/SearchBar";

// function ViewAttendance({ handleLogout, username }) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [projects, setProjects] = useState([]);
//   const [selectedProject, setSelectedProject] = useState("");
//   const [labour, setLabour] = useState([]);
//   const [attendanceData, setAttendanceData] = useState([]);
//   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
//   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
//   const [daysInMonth, setDaysInMonth] = useState(31);

//   useEffect(() => {
//     const fetchProjects = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_LOCAL_URL}/projects`
//         );
//         setProjects(response.data);
//       } catch (error) {
//         console.error("Error fetching projects:", error.message);
//         toast.error("Failed to load projects.");
//       } finally {
//         setIsLoading(false);
//       }
//     };
//     fetchProjects();
//   }, []);

//   const fetchLabourByProject = async (projectId) => {
//     setIsLoading(true);
//     try {
//       const labourResponse = await axios.get(
//         `${process.env.REACT_APP_LOCAL_URL}/labours/${projectId}`
//       );
//       const attendanceResponse = await axios.get(
//         `${process.env.REACT_APP_LOCAL_URL}/viewattendance/${projectId}?month=${selectedMonth + 1}&year=${selectedYear}`
//       );
//       setLabour(labourResponse.data);
//       setAttendanceData(attendanceResponse.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//       toast.error("Failed to load labour or attendance data.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     const getDaysInMonth = (month, year) =>
//       new Date(year, month + 1, 0).getDate();
//     setDaysInMonth(getDaysInMonth(selectedMonth, selectedYear));
//   }, [selectedMonth, selectedYear]);

//   const getAttendanceForDay = (labourId, day) => {
//     const record = attendanceData.find((rec) => {
//       const recordDate = new Date(rec.date);
//       return (
//         rec.labourId === labourId &&
//         recordDate.getDate() === day &&
//         recordDate.getMonth() === selectedMonth &&
//         recordDate.getFullYear() === selectedYear
//       );
//     });

//     if (record) {
//       const shifts = [];
//       if (record.day_shift) shifts.push("D");
//       if (record.day_shift) shifts.push("HD");
//       if (record.night_shift) shifts.push("N");
//       if (record.night_shift) shifts.push("A");
//       if (record.overtime_hours) shifts.push(record.overtime_hours);
//       return shifts.join("/");
//     }
//     return "";
//   };

//   const calculateTotalShifts = (labourId, shiftType) => {
//     return attendanceData.reduce((total, rec) => {
//       if (rec.labourId === labourId) {
//         if (shiftType === "day" && rec.day_shift) total++;
//         if (shiftType === "night" && rec.night_shift) total++;
//         if (shiftType === "overtime") total += rec.overtime_hours || 0;
//       }
//       return total;
//     }, 0);
//   };

//   const handleProjectChange = (projectId) => {
//     setSelectedProject(projectId);
//     if (projectId) {
//       fetchLabourByProject(projectId);
//     } else {
//       setLabour([]);
//       setAttendanceData([]);
//     }
//   };

//   return (
//     <div className='d-flex w-100 h-100 bg-white '>
//       {<Sidebar />}
//       <div className='w-100'>
//         <SearchBar username={username} handleLogout={handleLogout} />
//         <div className="container-fluid">
//           <ToastContainer />
//           <div className="row">
//             <div className="col-xl-12">
//               <div style={{ borderRadius: "20px", border: "1px solid #00509d" }} className='overflow-hidden'>
//                 <div style={{ backgroundColor: "#00509d" }} className="row no-gutters align-items-center p-3">
//                   <div className="col">
//                     <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
//                       <div className="nunito text-white" > Project Attendance
//                       </div>
//                       <div>
//                         <div className="d-flex align-items-center justify-content-center">
//                           <div className="">
//                             <label className="nunito text-white">Project:</label>
//                             <select
//                               className="button_details mx-1"
//                               value={selectedProject}
//                               onChange={(e) => handleProjectChange(e.target.value)}
//                             >
//                               <option value="">Select Project</option>
//                               {projects.map((proj) => (
//                                 <option key={proj.id} value={proj.id}>
//                                   {proj.projectName}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                           <div className="">
//                             <label className="nunito text-white">Month:</label>
//                             <select
//                               className="button_details mx-1"
//                               value={selectedMonth}
//                               onChange={(e) =>
//                                 setSelectedMonth(Number(e.target.value))
//                               }
//                             >
//                               {[...Array(12).keys()].map((month) => (
//                                 <option key={month} value={month}>
//                                   {new Date(0, month).toLocaleString("default", {
//                                     month: "long",
//                                   })}
//                                 </option>
//                               ))}
//                             </select>
//                             <label className="nunito text-white">Year:</label>
//                             <select
//                               className="button_details mx-1"
//                               value={selectedYear}
//                               onChange={(e) =>
//                                 setSelectedYear(Number(e.target.value))
//                               }
//                             >
//                               {[...Array(5).keys()].map((offset) => (
//                                 <option
//                                   key={offset}
//                                   value={new Date().getFullYear() - offset}
//                                 >
//                                   {new Date().getFullYear() - offset}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <hr className='m-0 p-0' />
//                 <div className=''>
//                   <div className="card-body">
//                     {isLoading ? (
//                       <div className="d-flex justify-content-center">
//                         <ThreeDots color="#00BFFF" height={80} width={80} />
//                       </div>
//                     ) : labour.length === 0 ? (
//                       <div className="text-center">
//                         No labour data available for the selected project.
//                       </div>
//                     ) : (
//                       <table className="table table-bordered">
//                         <thead>
//                           <tr>
//                             <th>Name</th>
//                             {[...Array(daysInMonth).keys()].map((day) => (
//                               <th key={day + 1}>{day + 1}</th>
//                             ))}
//                             <th> D</th>
//                             <th> NS</th>
//                             <th> HD</th>
//                             <th> AB</th>
//                             <th> OT hr</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {labour.map((lab) => (
//                             <tr key={lab.id}>
//                               <td>{lab.labourName}</td>
//                               {[...Array(daysInMonth).keys()].map((day) => (
//                                 <td key={day + 1}>
//                                   {getAttendanceForDay(lab.id, day + 1)}
//                                 </td>
//                               ))}
//                               <td>
//                                 {calculateTotalShifts(lab.id, "day")}
//                               </td>
//                               <td>
//                                 {calculateTotalShifts(lab.id, "night")}
//                               </td>
//                               <td>
//                                 {calculateTotalShifts(lab.id, "halfday_shift")}
//                               </td>
//                               <td>
//                                 {calculateTotalShifts(lab.id, "absent_shift")}
//                               </td>
//                               <td>
//                                 {calculateTotalShifts(lab.id, "overtime")}
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ViewAttendance;









import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";

function ViewAttendance({ handleLogout, username }) {
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [labour, setLabour] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [daysInMonth, setDaysInMonth] = useState(31);

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LOCAL_URL}/projects`
        );
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error.message);
        toast.error("Failed to load projects.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchProjects();
  }, []);

  const fetchLabourByProject = async (projectId) => {
    setIsLoading(true);
    try {
      const labourResponse = await axios.get(
        `${process.env.REACT_APP_LOCAL_URL}/labours/${projectId}`
      );
      const attendanceResponse = await axios.get(
        `${process.env.REACT_APP_LOCAL_URL}/viewattendance/${projectId}?month=${selectedMonth + 1}&year=${selectedYear}`
      );
      setLabour(labourResponse.data);
      setAttendanceData(attendanceResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error("Failed to load labour or attendance data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDaysInMonth = (month, year) =>
      new Date(year, month + 1, 0).getDate();
    setDaysInMonth(getDaysInMonth(selectedMonth, selectedYear));
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (selectedProject) {
      fetchLabourByProject(selectedProject);
    }
  }, [selectedMonth, selectedYear, selectedProject]);

  const getAttendanceForDay = (labourId, day) => {
    const record = attendanceData.find((rec) => {
      const recordDate = new Date(rec.date);
      return (
        rec.labourId === labourId &&
        recordDate.getDate() === day &&
        recordDate.getMonth() === selectedMonth &&
        recordDate.getFullYear() === selectedYear
      );
    });

    if (record) {
      const shifts = [];

      if (record.day_shift) shifts.push("D");
      if (record.halfday_shift) shifts.push("HD");
      if (record.night_shift) shifts.push("N");
      if (record.absent_shift) shifts.push("A");
      if (record.overtime_hours) shifts.push(record.overtime_hours);
      return shifts.join("/");
    }
    return ""; // Return empty string if no attendance record is found
  };

  const calculateTotalShifts = (labourId, shiftType) => {
    return attendanceData.reduce((total, rec) => {
      if (rec.labourId === labourId) {
        if (shiftType === "day" && rec.day_shift) total++;
        if (shiftType === "night" && rec.night_shift) total++;
        if (shiftType === "halfday_shift" && rec.halfday_shift) total++;
        if (shiftType === "absent_shift" && rec.absent_shift) total++;
        if (shiftType === "overtime") total += rec.overtime_hours || 0;
      }
      return total;
    }, 0);
  };

  const handleProjectChange = (projectId) => {
    setSelectedProject(projectId);
    if (projectId) {
      fetchLabourByProject(projectId);
    } else {
      setLabour([]);
      setAttendanceData([]);
    }
  };

  return (
    <div className='d-flex w-100 h-100 bg-white '>
      {<Sidebar />}
      <div className='w-100'>
        <SearchBar username={username} handleLogout={handleLogout} />
        <div className="container-fluid">
          <ToastContainer />
          <div className="row">
            <div className="col-xl-12 p-0">
              <div style={{ borderRadius: "20px", border: "1px solid #00509d" }} className='overflow-hidden'>
                <div style={{ backgroundColor: "#00509d" }} className="row no-gutters align-items-center p-3">
                  <div className="col">
                    <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                      <div className="nunito text-white" > Project Attendance
                      </div>
                      <div>
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="">
                            <label className="nunito text-white">Project:</label>
                            <select
                              className="button_details mx-1"
                              value={selectedProject}
                              onChange={(e) => handleProjectChange(e.target.value)}
                            >
                              <option value="">Select Project</option>
                              {projects.map((proj) => (
                                <option key={proj.id} value={proj.id}>
                                  {proj.projectName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="">
                            <label className="nunito text-white">Month:</label>
                            <select
                              className="button_details mx-1"
                              value={selectedMonth}
                              onChange={(e) =>
                                setSelectedMonth(Number(e.target.value))
                              }
                            >
                              {[...Array(12).keys()].map((month) => (
                                <option key={month} value={month}>
                                  {new Date(0, month).toLocaleString("default", {
                                    month: "long",
                                  })}
                                </option>
                              ))}
                            </select>
                            <label className="nunito text-white">Year:</label>
                            <select
                              className="button_details mx-1"
                              value={selectedYear}
                              onChange={(e) =>
                                setSelectedYear(Number(e.target.value))
                              }
                            >
                              {[...Array(5).keys()].map((offset) => (
                                <option
                                  key={offset}
                                  value={new Date().getFullYear() - offset}
                                >
                                  {new Date().getFullYear() - offset}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='m-0 p-0' />
                <div className=''>
                  <div className="card-body">
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <ThreeDots color="#00BFFF" height={80} width={80} />
                      </div>
                    ) : labour.length === 0 ? (
                      <div className="text-center">
                        No labour data available for the selected project.
                      </div>
                    ) : (
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{fontSize:"10px"}}>Name</th>
                            {[...Array(daysInMonth).keys()].map((day) => (
                              <th style={{fontSize:"10px"}} key={day + 1}>{day + 1}</th>
                            ))}
                            <th style={{fontSize:"10px"}}> D</th>
                            <th style={{fontSize:"10px"}}> NS</th>
                            <th style={{fontSize:"10px"}}> HD</th>
                            <th style={{fontSize:"10px"}}> AB</th>
                            <th style={{fontSize:"10px"}}> OT hr</th>
                          </tr>
                        </thead>
                        <tbody>
                          {labour.map((lab) => (
                            <tr key={lab.id}>
                              <td style={{fontSize:"10px"}}>{lab.labourName}</td>
                              {[...Array(daysInMonth).keys()].map((day) => {
                                const attendance = getAttendanceForDay(lab.id, day + 1);
                                return (
                                  <td
                                    style={{
                                      fontSize: "10px",
                                      color: attendance.includes("A") ? "#ff6262" : "black",
                                    }}
                                    key={day + 1}
                                  >
                                    {attendance}
                                  </td>
                                );
                              })}
                              <td style={{fontSize:"10px"}}>
                                {calculateTotalShifts(lab.id, "day")}
                              </td>
                              <td style={{fontSize:"10px"}}>
                                {calculateTotalShifts(lab.id, "night")}
                              </td>
                              <td style={{fontSize:"10px"}}>
                                {calculateTotalShifts(lab.id, "halfday_shift")}
                              </td>
                              <td style={{fontSize:"10px"}}>
                                {calculateTotalShifts(lab.id, "absent_shift")}
                              </td>
                              <td style={{fontSize:"10px"}}>
                                {calculateTotalShifts(lab.id, "overtime")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
}

export default ViewAttendance;